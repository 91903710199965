import React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import SEO from "src/components/SEO"
import Header from "src/components/Header"
import Footer from "src/components/Footer"
import Section from "src/components/Section"
import { Container } from "src/components/Grid"
import SanityRichText from "src/components/SanityRichText"
import TextLockup from "src/components/TextLockup"
import { typography, colors, util } from "src/styles"
import { blocksToText } from "src/utils/format"

const PageContent = styled(SanityRichText)`
  blockquote {
    background: ${colors.lightGrey};
    border-radius: var(--card-border-radius);
    color: ${colors.lightTextColor};
    ${typography.body}
    ${util.responsiveStyles("padding", 40, 32, 26, 20)}
    margin: 2em 0;
  }
  ul {
    li {
      &:before {
        background: ${colors.indigoLight};
        opacity: 0.5;
      }
    }
  }
`

const TextPage = ({ data }) => {
  const page = data?.sanityPage?.content?.main
  const pageMeta = data?.sanityPage?.content?.meta
  const path = page?.slug?.current
  const shareImage = pageMeta?.shareImage?.asset?.url

  return (
    <>
      <SEO
        pagePath={path}
        title={pageMeta?.metaTitle || page.title}
        description={
          pageMeta?.metaDescription ||
          blocksToText(page?._rawTextPageText).slice(0, 180) + "..."
        }
        shareImage={shareImage}
      />
      <Header
        hasAtf={false}
        location={path}
        hideToggle={!!page.hideCustomerToggle}
      />
      <Section
        setTheme="default"
        prevTheme={false}
        nextTheme="lightGrey"
        isFirstSection={true}
      >
        <Container
          css={css`
            ${util.responsiveStyles("margin-bottom", 60, 50, 40, 30)}
          `}
        >
          <div>
            <h1
              className={page.headingSize}
              css={css`
                text-align: center;
                max-width: 16em;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0.3em;
              `}
            >
              {page.title}
            </h1>
          </div>
        </Container>
        <Container
          css={css`
            ${util.responsiveStyles("max-width", 900, 800, 750, 700)}
            position: relative;
          `}
        >
          <div>
            <PageContent text={page._rawTextPageText} />
            <TextLockup actions={page.textPageActions} />
          </div>
        </Container>
      </Section>
      <Footer legalText={page?._rawLegalText} />
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...Page
    }
  }
`

export default TextPage
